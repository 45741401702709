import "./marketplace.scss";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import Transact       from "../transact/transact";
import { useTransact, useTransactDispatch } from "../transact/providers/TransactProvider";
import { useProfile } from "../../providers/ProfileProvider";

import { useEffect, useState, useRef } from "react";
import { useRates }           from "../../providers/Rates/RatesProvider";
import ReactSelect            from "react-select";
import { Loader2, RefreshCw } from "lucide-react";
import { useMarketplace } from "./providers/MarketplaceProvider";
import Big                from "big.js";
import { useNavigate }    from "react-router-dom";
import { useTransactionDetailsDispatch } from "../transactionDetails/providers/transactionDetailsProvider";
import CreateRequest      from "../transactionDetails/createRequest/createRequest";
import moment                 from "moment/moment";
import { getProcessedOrders } from "../../common/getProcessedOrders";
import { useFeatureFlags }    from "../../providers/FeatureFlags/FeatureFlagsProvider";
import { countries }    from "../../common/countries";
import OrderMudaPaymentMethods from "../../components/mudaOrderPaymentMethods/mudaOrderPaymentMethods";
import _ from "lodash";
import cn from "classnames";
import Countdown from "../../common/Countdown";
import PopModel                from "../../components/models/walletsModel";

const reactSelectStyleMarketplace = {
	control: (baseStyles, state) => ({
		...baseStyles,
		fontSize: ".9rem",
		fontWeight: "600",
		padding: ".2rem",
		borderRadius: "8px",
		width: "9rem",
		color: "#172c50",
		backgroundColor: "#ffffff",
		borderColor: "#3E9CF3",
	}),
};

const Marketplace = () => {

	const { profile } = useProfile();
	const [isPopoverVisible, setPopoverVisible] = useState(false);
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const [userDetails, setUserDetails] = useState(profile);
	const [modelStatus, setModelStatus]           = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse]         = useState({}); 
	const [countriesList, setCountriesList]       = useState([]); 
	const createReqeustPopoverRef = useRef(null);
	const popModelRef = useRef(null);
	
	const [marketplaceState, setMarketplaceState] = useState({
		currencyOptions: [],
		buyCurrencyFilter: [],
		sellCurrencyFilter: [],
	});
	const { currencies, walletCurrencies } = useRates();
	const { marketplaceOrders, isFetchingMarketplaceOrders, reloadMarketPlaceOrders } = useMarketplace();
	const transactDispatch = useTransactDispatch();
	const transactState    = useTransact();
	const transactionDetailsDispatch = useTransactionDetailsDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const newMarketplaceState = { ...marketplaceState };
		// build currency options
		const currencyOptions = walletCurrencies.map((currency) => ({ value: currency.code, label: currency.code }));
		newMarketplaceState.currencyOptions = currencyOptions;

		setMarketplaceState(newMarketplaceState);
		const countryOptions = countries.map((country) => ({
																				label: country?.name,
																				value: country?.code,
														   }));
		setCountriesList(countryOptions);

	}, []);

	const fetchMudaWallets = async (options) => {
		const defaultCountry = countries.filter((option) => option?.currency?.code === options?.code);
		await getMudaWallet({ country: options?.code, currency: options.currency, paymode: "bank"});
	}

	const processedOrders = () => {
		const orders = getProcessedOrders({ orders: marketplaceOrders });

		const buyCurrencyFilter = marketplaceState.buyCurrencyFilter;
		const sellCurrencyFilter = marketplaceState.sellCurrencyFilter;
		let filterMode = "NONE";
		if (buyCurrencyFilter.length > 0 && sellCurrencyFilter.length > 0) {
			filterMode = "BOTH";
		} else if (buyCurrencyFilter.length > 0) {
			filterMode = "BUY";
		} else if (sellCurrencyFilter.length > 0) {
			filterMode = "SELL";
		}

		const filteredOrders = orders.filter((order) => {
			let showOrder = true;
			const buyCurrency = order.pair.split("-")[0].trim();
			const sellCurrency = order.pair.split("-")[1].trim();

			switch (filterMode) {
				case "BUY":
					showOrder = buyCurrencyFilter.includes(buyCurrency);
					break;
				case "SELL":
					showOrder = sellCurrencyFilter.includes(sellCurrency);
					break;
				case "BOTH":
					showOrder = buyCurrencyFilter.includes(buyCurrency) && sellCurrencyFilter.includes(sellCurrency);
					break;
				case "NONE":
				default:
					showOrder = true;
					break;
			}

			return showOrder;
		});

		const sortedOrders = _.sortBy(filteredOrders, ["_rawTxn.createdAt"]).reverse();

		return sortedOrders;
	};

	const viewOrder = ({ order }) => {

		transactionDetailsDispatch({ type: "open", order, back: "/marketplace" });
		if(userDetails?.id !== undefined && order?.createdBy  !== userDetails?.id){
		   return showModel({ operation_type: 'order_details', operation_method_type: 'order_details'});
		} else {
		   navigate(`/transactiondetails/${order.id}`);
		}
		
	};

	const buyCurrencyFilterChanged = (evt) => {
		let buyCurrencyFilter = [];
		if (!_.isEmpty(evt)) {
			const currency = evt.value;
			buyCurrencyFilter = [`${currency}`];
		}

		const newMarketplaceState = { ...marketplaceState };
		newMarketplaceState.buyCurrencyFilter = buyCurrencyFilter;
		setMarketplaceState(newMarketplaceState);
	};

	const sellCurrencyFilterChanged = (evt) => {
		let sellCurrencyFilter = [];
		if (!_.isEmpty(evt)) {
			const currency = evt.value;
			sellCurrencyFilter = [`${currency}`];
		}

		const newMarketplaceState = { ...marketplaceState };
		newMarketplaceState.sellCurrencyFilter = sellCurrencyFilter;
		setMarketplaceState(newMarketplaceState);
	};

	useEffect(() => {
		   const modelAutoFun = () => {	
			if(transactState?.modelOption !== undefined && transactState?.modelOption !== ""){
			  popModelRef.current.hidePopover();
			  transactDispatch({ type: "modelClose", modelOption: ""}); 
			}	    
		   }
		   modelAutoFun();
	}, [transactState]);

	
	/**  start the trading withdraw side model */ 
	const showModel = (options) => {
		try{
		  if(options.operation_type !== ""){
			  setModelDataContent(options)
			  setModelStatus(true);
		   }
		}	catch(e){}
	  }
	  
	  // close model popup
	  const modelStatusTrack = (e) => {
		try{   
		  if(e?.operation_type !== undefined && e?.operation_type !== "" ){
			setModelStatus(false);
			setModelDataContent(e);
			setModelStatus(true);
		  }else if(e?.trade_request_operation !== undefined && e?.trade_request_operation){
			setModelStatus(false);
			createReqeustPopoverRef.current.showPopover();
		  } else {
			setModelStatus(false);
		  }
		}	catch(e){}	
	  }
  
	  // toggle depost model popup option
	   const modelToggleMethodOption = (e) => {
		  try{   
			 setModelDataContent(e)
		  }	catch(e){}	
	}
  
	return (
		<div id="marketplace_page">

			<div className="heading">
			   <div className="title">Marketplace</div>
			   <RefreshCw onClick={reloadMarketPlaceOrders} className={cn("refresh_icon", { rotating: isFetchingMarketplaceOrders })} />
			</div>

			<div id="create_request_popover" popover="auto" ref={createReqeustPopoverRef}>
			   <CreateRequest />
			</div>
			
			<PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} toggleOperationMethodType={(e) => modelToggleMethodOption(e)}></PopModel>


			<div className="trading_pairs_and_controls">
				<div className="lhs">
					<div className="trading_pair">
						<div className="label">Pick the trading pair</div>
						<div className="inputs">
							<ReactSelect
								styles={reactSelectStyleMarketplace}
								options={marketplaceState.currencyOptions}
								onChange={buyCurrencyFilterChanged}
								isClearable={true}></ReactSelect>
							<ReactSelect
								styles={reactSelectStyleMarketplace}
								options={marketplaceState.currencyOptions}
								onChange={sellCurrencyFilterChanged}
								isClearable={true}></ReactSelect>
						</div>
					</div>
				</div>
				<div className="rhs">
					<div className="controls">
						{/* Transact Buttons */}
						<button
							onClick={() => {
								transactDispatch({ type: "transact" });
							}}
							className="control trade"
							popovertarget="transact_popover"
							popovertargetaction="show">
							<div className="control_icon">
								<img src={dashboard_trade_icon} alt="trade icon" />
							</div>
							<div className="control_title">TRADE</div>
						</button>
						{isFeatureFlagEnabled("deposits") && (
							<button className="control deposit">
								<div className="control_icon">
									<img src={dashboard_deposit_icon} alt="deposit icon" />
								</div>
								<div className="control_title">DEPOSIT</div>
							</button>
						)}
						{isFeatureFlagEnabled("withdrawals") && (
							<button className="control withdraw">
								<div className="control_icon">
									<img src={dashboard_withdraw_icon} alt="withdraw icon" />
								</div>
								<div className="control_title">WITHDRAW</div>
							</button>
						)}

						<div id="transact_popover" popover="auto" ref={popModelRef}>
							<Transact />
						</div>
					</div>
				</div>
			</div>

			{isFetchingMarketplaceOrders && (
				<div className="marketplace_orders_loader_container">
					Loading marketplace orders...
					<Loader2 className="marketplace_orders_loader" />
				</div>
			)}

			{!isFetchingMarketplaceOrders && (
				<table className="marketplace_orders_table">
					<thead>
						<tr>
							<th>
								Pair <span className="help_text">( Buy - Sell )</span>
							</th>
							<th>Rate</th>
							<th>Available volume</th>
							<th>Payment methods</th>
							<th>Expiry</th>
						</tr>
					</thead>
					<tbody>
						{processedOrders().map((order) => (
							<tr
								className="marketplace_order"
								key={order.id}
								onClick={() => {
									viewOrder({ order });
								}}>
								<td>{order.pair}</td>
								<td>{order.price}</td>
								<td>{order.balance}</td>
                                <td>

									{ (order?.createdBy === profile.id)?
									  
									  order?.paymentMethods?.map((method, index) => (
										<li key={index}>
											{method?.type === 'PHONE_NUMBER' && 'Mobile Money'}
											{method?.type === 'BANK_ACCOUNT' && method?.bankName}
											{method?.type !== 'PHONE_NUMBER' && method?.type !== 'BANK_ACCOUNT' && 'Account Wallet'}
										</li>
									)):
									    <OrderMudaPaymentMethods currency={order?._rawTxn?.buyCurrency} />
									}
								</td> 

								<td className="text-left">
									<Countdown to={order._rawTxn.expiry}></Countdown>
								</td>
								{/* <td>{order.totalVolume}</td> */}
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default Marketplace;
