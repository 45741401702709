import React, { useState, useEffect } from "react";
import { Loader2, Info } from "lucide-react";
import { usePaymentMethods } from "../../../providers/PaymentMethodsProvider";
import ChangeAccountPassword from  "./forms/changePasswordForm/changepassword"
import "./passwordandsecurity.scss";

const PasswordSecurity = () => {

	const [bankAccounts, setBankAccounts] = useState([]); 
	const [phoneNumbers, setPhoneNumbers] = useState([]); 
	const [walletAddress, setWalletAddress] = useState([]); 

	const [selectedTab, setSelectedTab] = useState("my account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [toggleSettingsSection, setToggleSettingsSection] = useState(""); 

	
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 
	const { paymentMethods, reloadPaymentMethods,  refreshingPaymentMethods }  = usePaymentMethods(); 

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 
											
	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const showModel = (options) => {
	   if(options.operation_type !== ""){
		
		  // close all drop downs 
	      setSelectedAccountMoreDetails("")
	      setSelectedAccountDetails("")
		  setModelDataContent(options)
		  setModelStatus(true);
	   }
	}

	const toggleSettingsModel = (options) => {
		if(options.operation_type !== ""){
		  options.operation_type = (options.operation_type === toggleSettingsSection)? "":options.operation_type	
		  setToggleSettingsSection(options.operation_type)
		} else {
		  setToggleSettingsSection("")	
		}
	}
	
	const modelStatusTrack = (e) => {
        setModelStatus(false);
	}

	useEffect(() => {
		const wallets = paymentMethods.filter((w) => w.type === 'WALLET_ADDRESS');
		setWalletAddress(wallets)
		const bank    = paymentMethods.filter((w) => w.type === 'BANK_ACCOUNT');
		setBankAccounts(bank);
		const phone   = paymentMethods.filter((w) => w.type === 'PHONE_NUMBER');
		setPhoneNumbers(phone);
	},[]);

	return (
		<div className="accountsettings_paymentmethods_page">
			<div className="paymentmethods_heading">Password</div>
			<div className="paymentmethods_tabs">
        		<div className="payment_methods_tabs_body">		

				  <div className="payment_methods_tabs_body_card flex_container flexing_content full_width">
					<div className="payment_methods_tabs_body_card_content full_width"> 
				      <h2>Password management</h2>
					</div>  	
					<div className="payment_methods_tabs_body_card_content column_100 clearfix"></div>
					<div className="payment_methods_tabs_body_card_content column_100">  
					  <p>You can change your password. Use at least 8 characters, 1 number, 1 uppercase, 1 lowercase letter & a special character</p>	 
					</div>
					<div className={(toggleSettingsSection === 'change_account_password')?"payment_methods_tabs_body_card_content column_100":"hidden"}>  
					  <ChangeAccountPassword></ChangeAccountPassword>
					</div>

					<div className="column_70">  
					</div>
					<div className="payment_methods_tabs_body_card_btn column_30"> 
					  <button className="add_new_button" onClick={() => toggleSettingsModel({operation_type: 'change_account_password'})}>{(toggleSettingsSection === 'change_account_password')? 'Close Section':'Change Password'}</button>
					</div>
                  </div>
				</div>


			</div>
		</div>

	);
};
export default PasswordSecurity;
