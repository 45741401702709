import React, { useState, useEffect, useRef } from "react";
import "./wallets.scss";
import Transact from "../transact/transact";
import { formatAmount } from "../../common/formatAmount";
import WalletBalance from "../../components/wallets/walletBalance";
import {dynamicImage} from "../../common/dynamicImage";
import { appPolling } from "../../common/appPolling";
import Switch from "react-switch";

import reload_page_icon from "../../assets/refresh.svg";
import dashboard_trade_icon from "../../assets/dashboard_trade_icon.svg";
import dashboard_deposit_icon from "../../assets/dashboard_deposit_icon.svg";
import dashboard_withdraw_icon from "../../assets/dashboard_withdraw_icon.svg";
import dashboardTradeIcon from "../../assets/wallets/exchange.svg";
import dashboardDepositIcon from "../../assets/wallets/deposit.svg";
import dashboardWithdrawIcon from "../../assets/wallets/withdraw.svg";
import moreWalletActionsIcon from "../../assets/wallets/more-wallet-actions.svg";
import tetherIcon from "../../assets/wallets/tether.svg";
import { useWallets } from "../../providers/WalletsProvider";
import { useRates } from "../../providers/Rates/RatesProvider";
import PopModel     from "../../components/models/walletsModel";
import ContentLoader from "../../components/loaders/contentLoader";
import { useTransact, useTransactDispatch } from "../transact/providers/TransactProvider";
import Big from "big.js";
// import Switch from "react-switch";
// import Select from 'react-select';

const Wallets = () => {
	const popModelRef = useRef(null);
	const { isLoadingRates, convert, rates, currencies, getRate } = useRates();
	const { wallets, getAccountWallet, isLoadingWallets, getUserAccountWallet } = useWallets();
	const transactDispatch                               = useTransactDispatch();
    const transactState    = useTransact();
	const [pageReload, setPageReload]                    = useState(false);
	const [zeroBalance, setShowCurrencyWithZeroBalance ] = useState(false);
	const [marketplaceState, setMarketplaceState] = useState({
																buyCurrencyFilter: [],
																sellCurrencyFilter: [],
																walletsOptions: [
																	 {label: 'All', value: 'all'},
																	 {label: 'USDT', value: 'usdt'}
																	],
																accountWallets: [],
																currencyOptions: [],	
															});
															
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 
	const getWalletPrice = (wallet) => {
		let amountStr = "-1";
		try {
			const amount = convert({
				rates,
				from: wallet.currency,
				to: "USD",
				amount: wallet.balance,
			});
			amountStr = `USD ${Big(amount).round(4).toString()}`;
		} catch (err) {}
		return amountStr;
	};

	const getWalletRate = (wallet) => {
		let rateStr = "-1";
		try {
			let rate = getRate({
				rates,
				from: wallet.currency,
				to: "USD",
			});
			rateStr = `${Big(rate).round(4).toString()} USD / ${wallet.currency}`;

			if (Big(rate).lt(1)) {
				rate = getRate({
					rates,
					from: "USD",
					to: wallet.currency,
				});
				rateStr = `${Big(rate).round(4).toString()} ${wallet.currency} / USD`;
			}
		} catch (err) {}
		return rateStr;
	};

	const autoFetchWallets = () => {
		getUserAccountWallet();
	}
	
	// auto poll balances
	// appPolling(autoFetchWallets, 5000);
	
    const initCollection = async () => {
		try{	
		  const currencyOptions =   currencies.map((currency) => ({
															  value: currency.code,
															  label: currency.code,
														  }));											
		  const walletCollection = await getAccountWallet();
		  setMarketplaceState((prevState) => ({
												  ...prevState,
												  accountWalletCollection:  walletCollection,
												  currencyOptions: currencyOptions,
												  accountWallets:  wallets
											  }));
									   
		  

		}catch(e){}
	}

    const showZeroBalance = async () => {
		try{	
		  const balance = localStorage.getItem('showCurrencyWithZeroBalance');
		  if(balance){
            setShowCurrencyWithZeroBalance(balance === "true" ? true : false);
		  }
		}catch(e){}
	}

	

	useEffect(() => {
	   initCollection();
	   showZeroBalance()	
	}, [wallets, marketplaceState.accountWallets, zeroBalance])

	useEffect(() => {
		const modelAutoFun = () => {	
			if(transactState?.modelOption !== undefined && transactState?.modelOption !== ""){
			  popModelRef.current.hidePopover();
			  transactDispatch({ type: "modelClose", modelOption: ""}); 
			}	    
		}
		initCollection();
		modelAutoFun();
	}, [transactState]);
	
	const reloadPage = async (options) => {
		setPageReload(true);
		await getUserAccountWallet();
        await new Promise((r) => setTimeout(r, 600));
		initCollection();
		showZeroBalance();
		setPageReload(false);
	}

	const handleChange = (checked) => {
      try{	

		setShowCurrencyWithZeroBalance(checked);
		setMarketplaceState((prevState) => ({
												...prevState,
												accountWallets: walletCollection,
												currencyOptions: currencyOptions
											}));
	  } catch (err) {
		console.log(err);
	  }	
	}

	const changeDefaultCurreny = () => {

	}

	// toggle depost model popup option
 	const modelToggleMethodOption = (e) => {
		try{   
		   setModelDataContent(e)
		}	catch(e){}	
	}

	/**  start the trading withdraw side model */ 
	const showModel = (options) => {
		try{
		  if(options.operation_type !== ""){
			   // close all drop downs 
			  setModelDataContent(options)
			  setModelStatus(true);
		   }
		}	catch(e){}
	}

	// close model popup
	const modelStatusTrack = (e) => {
		try{   
	  
		  if(e?.operation_type !== undefined && e?.operation_type !== "" ){
			setModelStatus(false);
			setModelDataContent(e);
			setModelStatus(true);
		  } else {
			  
			setModelStatus(false);
		  }
		}	catch(e){}	
	}
	
	const hideZeroBalances = async (option) => {
		try{	
	      localStorage.setItem("showCurrencyWithZeroBalance", option);		
 		  setShowCurrencyWithZeroBalance(option);
		  const walletCollection = await getAccountWallet();
		  setMarketplaceState((prevState) => ({
												  ...prevState,
												  accountWalletCollection:  walletCollection,
												  accountWallets:  wallets
											  }));
		
		} catch (err) {
		  console.log(err);
		}	
	}

	return (
	  <>
	 	<div id="transact_popover" popover="auto" ref={popModelRef}>
			<Transact />
		</div>
		<div className="wallet_page scroll_right flexing_content flex_container">
			<PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} toggleOperationMethodType={(e) => modelToggleMethodOption(e)}></PopModel> 
			<div className="section_main_header">
			  <div className="left_section text_left">
				<span>Wallets</span>
			  </div>
			</div>

			<div className="section_main_sub_header flexing_content flex_container">
			  
			  <div className="left_section text_left column_50  nopadding">
			    <div className="top_account_details_block flexing_content flex_container"> 

					<WalletBalance wallets={wallets} />
					
					{/* <div className="right_section text_left column_40 default_currency_block">
					<div className="default_currency_title text_left">Default currency</div>
					    <Select
							value={""}
							className="input_select"
							onChange={changeDefaultCurreny}
							options={marketplaceState.currencyOptions}
							isSearchable="true" />				
					</div> */}

				</div>	
			  </div>	
              <div className="right_section text_right column_50 flexing_content flex_container dash_top_buttons">
				    <button
						onClick={() => {
										reloadPage({ type: "reload" });
									}}
						className="control reload_btn">
						<div className="control_icon">
							<img src={reload_page_icon} alt="reload icon" className={pageReload? "roatating":""} />
						</div>
					</button>
			        <button
							data-testid="walletspage-tradebtn"
							onClick={() => {
								transactDispatch({ type: "transact" });
							}}
							className="control trade"
							popovertarget="transact_popover"
							popovertargetaction="show">
							<div className="control_icon">
								<img src={dashboard_trade_icon} alt="trade icon" />
							</div>
							<div className="control_title">TRADE</div>
						</button>
		 			
					<button className="control deposit" 
					        onClick={() => showModel({operation_type: 'crediting', 
					                                  operation_method_type: 'deposit' })}>
		 			  <div className="control_icon">
		 				<img src={dashboard_deposit_icon} alt="deposit icon" />
		 			  </div>
		 			  <div className="control_title">DEPOSIT</div>
		 			</button>
		 			
					<button className="control withdraw" 
					   onClick={() => showModel({ operation_type: 'crediting', 
					                              operation_method_type: 'withdraw'})}>
		 			   <div className="control_icon">
		 				 <img src={dashboard_withdraw_icon} alt="withdraw icon" />
		 			   </div>
		 			   <div className="control_title">WITHDRAW</div>
					</button>
			  </div>
			  <div className="left_section text_left column_50  nopadding">
			    <div className="show_zeo_balances clearfix group  text_left"> 
				  <span>Show wallets with zero balance</span> 
				  <Switch
                           checked={zeroBalance}
                           onChange={hideZeroBalances}
                           onColor="#2290F6"
                           onHandleColor="#ffffff"
                           handleDiameter={18}
                           uncheckedIcon={false}
                           checkedIcon={false}
                           boxShadow="0px 1px 2px rgba(0, 0, 0, 0.6)"
                           activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
                           height={26}
                           width={60}
                           className="react-switch"
                           id="material-switch" />	
				</div>
			  </div>


            </div>

            <div  className="wallet_listing">
			  <table className="main_table">
				<thead>
				  <tr>
					<th className="flexing_content flex_container">
						 <span>Wallet</span>
                         {/* <Select
							value={""}
							className="input_select"
							onChange={changeDefaultCurreny}
							options={marketplaceState.walletsOptions}
							isSearchable="true" /> */}
					</th>
					<th> Amount </th>
					<th> Action </th>
				  </tr>
				</thead>

				<tbody>
				  
			      {pageReload && 
				    <tr>  <td colSpan="3"> <ContentLoader /> </td> </tr> }
			       			

				  {!pageReload && marketplaceState?.accountWallets?.map((wallet, walletKey) => (
					<tr data-testid="wallet" data-testwalletcurrency={wallet.currency} data-testwalletbalance={`${wallet.balance}`} key={walletKey}  className={(!zeroBalance && parseFloat(wallet.balance) <= 0)? 'hidden':''}>
						<td> 
							<div className="flexing_content flex_container text-right"> 
								<img src={dynamicImage({imageName: wallet.currency})} alt={wallet.currency}  className="coin_icon"/>
								<strong>{wallet.description}</strong>
							</div> 
							</td>
							<td> 
							<div className="flexing_content flex_container">
								<strong> {formatAmount(wallet.balance)} </strong> 
								<span> {wallet.currency} </span>
							</div> 
							</td>
							<td  className="action_td_tabs">

							<div className="flexing_content flex_container text-right show_more_actions">
								<img src={moreWalletActionsIcon} alt="widthdraw" className="more_action_icon" /> 
							</div> 
							<div className="flexing_content flex_container text-right mobile_wallet_actions hidding_more_actions">
								<button
									onClick={() => {
									   transactDispatch({ type: "transact", initSellCurrency: `${wallet?.currency || ""}` });
									}}
									className="control_ trade action_icon action_icon_token"
									popovertarget="transact_popover"
									popovertargetaction="show">
									<div className="control_icon">
										<img src={dashboard_trade_icon} alt="trade icon" />
									</div>
								</button>


								<button
									onClick={() => showModel({operation_type: 'crediting', 
										operation_method_type: 'deposit', data: wallet})}
									className="control_ action_icon action_icon_token">
									<div className="control_icon">
										<img src={dashboardDepositIcon} alt="trade icon" />
									</div>
								</button>

								<button
									onClick={() => showModel({ operation_type: 'crediting', 
										operation_method_type: 'withdraw', data: wallet})}
									className="control_ action_icon action_icon_token">
									<div className="control_icon">
										<img src={dashboardWithdrawIcon} alt="trade icon" />
									</div>
								</button>	 

							</div> 
							</td>
						</tr>

				  ))}	
					

					</tbody>
				</table>
			</div> 
		</div>	
	  </>	

	);
};
export default Wallets;
